import React from "react"

export default function VotingRecordTableHeader() {
  return (
    <>
      <tr>
        <th colSpan="3" className="text-xs text-right">
          CA Cities for Local Control position:
        </th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Support</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
        <th className="text-xs">Oppose</th>
      </tr>
      <tr>
        <th colSpan="3" className="text-xs text-right">
          CalCities/League Position:
        </th>
        <th className="text-xs text-green-500">Oppose</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-green-500">Oppose</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
        <th className="text-xs text-red-500">Watch</th>
      </tr>
    </>
  )
}
